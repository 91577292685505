import React from 'react';

import dayjs from 'dayjs';
import { useNavigate } from 'react-router';

import Copy from '@travauxlib/shared/src/components/DesignSystem/assets/Copy.svg?react';
import DeleteFilled from '@travauxlib/shared/src/components/DesignSystem/assets/DeleteFilled.svg?react';
import Download from '@travauxlib/shared/src/components/DesignSystem/assets/DownloadFilled.svg?react';
import PaperSearch from '@travauxlib/shared/src/components/DesignSystem/assets/PaperSearch.svg?react';
import Send from '@travauxlib/shared/src/components/DesignSystem/assets/Send.svg?react';
import { Table } from '@travauxlib/shared/src/components/DesignSystem/components/Table';
import { RowAction } from '@travauxlib/shared/src/components/DesignSystem/components/Table/types';
import { Tag } from '@travauxlib/shared/src/components/DesignSystem/components/Tag';
import { EURCurrency } from '@travauxlib/shared/src/components/EURCurrency';
import { DevisStatuses } from '@travauxlib/shared/src/types';
import { devisFactureStatuses } from '@travauxlib/shared/src/utils/constants';
import { toCalendarFormat } from '@travauxlib/shared/src/utils/time';

import { useDuplicateDevis } from 'features/Project/api/useDuplicateDevis';
import { Devis } from 'types';

import { useOpenTransferPreChiffrageModal } from './TransferPreChiffrageModal';

import { useArchiveDevis } from '../api/useArchiveDevis';

const prechiffrageDate = (prechiffrage: Devis): { label: string; value?: string } => {
  switch (prechiffrage.status) {
    case DevisStatuses.sent:
      return { label: 'Envoyé', value: prechiffrage.sentAt };
    default:
      return { label: 'Créé', value: prechiffrage.createdAt };
  }
};

type Props = {
  prechiffrages: Devis[];
};

export const PrechiffragesBlock: React.FC<Props> = ({ prechiffrages }) => {
  const duplicateDevis = useDuplicateDevis();
  const archiveDevis = useArchiveDevis();
  const navigate = useNavigate();

  const openTransferPrechiffrageModal = useOpenTransferPreChiffrageModal();
  return (
    <div className="bg-white rounded-md py-md mb-md shadow-sm mt-md !text-neutral-800">
      <div className="font-bold text-h4 mr-xs pl-md">Devis estimatif</div>
      <Table
        columnConfigs={[
          {
            name: 'Nom',
            columnKey: 'title',
            renderValue: (_, { title, version }) => (
              <div>
                <div className="text-sm text-neutral-700">Version {version}</div>
                <div>{title}</div>
              </div>
            ),
          },
          {
            name: 'Type',
            columnKey: 'isFromConfiguration',
            renderValue: isFromConfiguration => (
              <div>{isFromConfiguration ? 'Devis du configurateur' : 'Pré-chiffrage'}</div>
            ),
          },
          {
            name: 'Status',
            columnKey: 'status',
            renderValue: (status: DevisStatuses) => (
              <Tag
                size="md"
                variant={devisFactureStatuses[status].level}
                label={
                  status === DevisStatuses.sent
                    ? 'Envoyé au client'
                    : devisFactureStatuses[status].label
                }
              />
            ),
          },
          {
            name: 'Date',
            renderValue: (_, prechiffrage) => {
              const { label, value } = prechiffrageDate(prechiffrage);
              return (
                <div>
                  <div className="text-sm text-neutral-700">{label}</div>
                  <div className="text-b2"> {toCalendarFormat(dayjs(value))}</div>
                </div>
              );
            },
          },

          {
            name: 'Montant HT',
            columnKey: 'prixTotalHT',
            renderValue: (prixTotalHT: number) => (
              <strong>
                <EURCurrency amount={prixTotalHT} forceFullPattern className="font-normal" />
              </strong>
            ),
          },
          {
            name: 'Montant TTC',
            columnKey: 'prixTotalTTC',
            renderValue: (prixTotalTTC: number) => (
              <strong>
                <EURCurrency amount={prixTotalTTC} forceFullPattern className="font-normal" />
              </strong>
            ),
          },
        ]}
        items={prechiffrages}
        defaultSort={{ columnKey: 'createdAt', order: 'desc' }}
        onRowClick={({ token, status }) =>
          status === 'draft'
            ? navigate(`../devis/${token}`)
            : window.open(`${APP_CONFIG.appURL}/pro/devis/${token}`)
        }
        rowActionsPosition="top-end"
        rowActions={({ projectUuid, token, status }) =>
          [
            {
              label: 'Voir',
              action:
                status === 'draft'
                  ? () => navigate(`../devis/${token}`)
                  : () => window.open(`${APP_CONFIG.appURL}/pro/devis/${token}`),
              icon: <PaperSearch />,
            },
            {
              label: 'Télécharger',
              action: () => window.open(`${APP_CONFIG.apiURL}/devis-pro/${token}/pdf`),
              icon: <Download />,
            },
            {
              label: 'Dupliquer',
              action: () => duplicateDevis({ projectUuid, token }),
              icon: <Copy />,
            },
            {
              label: 'Supprimer',
              action: () => archiveDevis({ projectUuid, token }),
              icon: <DeleteFilled />,
            },
            {
              label: 'Transférer vers un projet',
              action: () =>
                openTransferPrechiffrageModal({
                  projectUuid,
                  token,
                }),
              icon: <Send />,
            },
          ] as RowAction[]
        }
      />
    </div>
  );
};
