import React from 'react';

import { optionActivity } from '@travauxlib/shared/src/types';
import { ProRegistrationAdminView } from '@travauxlib/shared/src/types/api/admin/ProRegistrationAdminView';
import { CompanyActivity } from '@travauxlib/shared/src/types/api/domain/procompany/CompanyActivity';
import { formatFrenchDate } from '@travauxlib/shared/src/utils/time';

import { getChiffreAffairesDeclareLabel } from './InfoSection';

export const DemandeChantierResults: React.FC<Partial<ProRegistrationAdminView>> = ({
  chiffreAffairesDeclare: chiffreAffaires = '',
  activity,
  interventionZone = [],
  companyCreationDate,
}) => (
  <>
    <div>
      <br />
      Résultats demande de chantier :<br />
      <br />
      <div>
        Activité : {optionActivity.find(activityOption => activityOption.value === activity)?.label}
      </div>
      {activity !== CompanyActivity.ArchiHMONP && activity !== CompanyActivity.ArchiInterieur && (
        <>
          <div>
            Zones d'intervention :{' '}
            {interventionZone.map(department => (
              <span key={department}>{department} </span>
            ))}
          </div>
          <div>Chiffre d'affaire : {getChiffreAffairesDeclareLabel(chiffreAffaires)}</div>
          <div>Date de création de l'entreprise: {formatFrenchDate(companyCreationDate)}</div>
        </>
      )}
    </div>
  </>
);
