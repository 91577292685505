export enum DocumentTag {
  DPE = 'DPE',
  Photo = 'photo',
  Devis = 'devis',
  Authorization = 'authorization',
  ExistingPlan = 'existing_plan',
  TechnicalPlan = 'technical_plan',
  Decoration = 'decoration',
  BlueprintSketch = 'blueprint_sketch',
  Surfaces = 'surfaces',
  BeforeChantier = 'before_chantier',
  DuringChantier = 'during_chantier',
  AfterChantier = 'after_chantier',
  Fourniture = 'fourniture',
  Other = 'other',
  Plan = 'plan',
  Admin = 'added_by_admin',
}
