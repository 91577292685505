import { useQuery } from '@tanstack/react-query';

import { ProRegistrationAdminView } from '@travauxlib/shared/src/types/api/admin/ProRegistrationAdminView';
import { request } from '@travauxlib/shared/src/utils/request';

const fetchProRegistrations = (): Promise<{ data: ProRegistrationAdminView[] }> =>
  request(`${APP_CONFIG.apiURL}/admin/pro-registrations`);

export const PRO_REGISTRATION_KEY = 'pro-registrations';

export const useProRegistrations = (): ProRegistrationAdminView[] | undefined => {
  const { data } = useQuery<{ data: ProRegistrationAdminView[] }>({
    queryKey: [PRO_REGISTRATION_KEY],
    queryFn: fetchProRegistrations,
    refetchInterval: 4000,
  });
  return data?.data;
};
