import React from 'react';

import { Tag, Variant } from '@travauxlib/shared/src/components/DesignSystem/components/Tag';
import { MultilineParagraph } from '@travauxlib/shared/src/components/MultilineParagraph';

import { Lead } from 'types';

const scoresLabelsBadgeLevels: {
  [K in NonNullable<Lead['scoreLabel']>]: Variant;
} = {
  top: 'success',
  bon: 'info',
  moyen: 'primary',
  mauvais: 'error',
};

const isValidAnswer = (answer: string | undefined): answer is string =>
  !!answer && answer !== 'N/A'; // a bit dirty for the moment, we should remove N/A answers

const Answers: React.FC<{ lead: Lead }> = ({ lead }) => {
  const answers: [string, string | undefined][] = [
    ['Besoin client', lead.besoinClient],
    ['Type de projet', lead.typeProjet],
    ['Date des travaux', lead.dateTravaux],
    ["Besoin d'un architecte", lead.besoinArchitecte],
    ['Critère de sélection', lead.critereSelection],
    ['Description', lead.description],
    ['Type de bien', lead.typeBien],
    ["Stade d'achat", lead.stadeAchat],
    [
      'Surface totale',
      lead.surface !== undefined ? `${lead.surface.toLocaleString()} m²` : undefined,
    ],
    ['Email commercial', lead.emailCommercial],
    [
      'Type de rénovation énergétique',
      lead.typeRenovationEnergetique?.length
        ? lead.typeRenovationEnergetique.join(', ')
        : undefined,
    ],
  ];

  return (
    <>
      {isValidAnswer(lead.budget) && (
        <div>
          <span>Budget :&nbsp;</span>
          <Tag
            variant={lead.budget === 'Moins de 10 000 €' ? 'error' : 'success'}
            label={lead.budget}
            size="md"
          />
        </div>
      )}
      {answers
        .filter(([, label]) => isValidAnswer(label))
        .map(([questionLabel, answerLabel]) => (
          <div key={questionLabel}>
            <span>{questionLabel} :&nbsp;</span>
            <strong>
              {answerLabel?.includes('\n') ? (
                <MultilineParagraph text={answerLabel} />
              ) : (
                answerLabel
              )}
            </strong>
          </div>
        ))}
    </>
  );
};

export const Description: React.FC<{ lead: Lead }> = ({ lead }) => (
  <>
    <h4>
      {lead.shortTitle}{' '}
      {lead.scoreLabel ? (
        <Tag
          size="md"
          variant={scoresLabelsBadgeLevels[lead.scoreLabel]}
          label={lead.scoreLabel}
          className="uppercase"
        />
      ) : (
        <Tag size="md" variant="primary" label="Score inconnu" className="uppercase" />
      )}
    </h4>
    <div className="mb-md">
      {lead.parrainPipedriveDealUrl && (
        <Tag size="md" variant="info" className="!mx-xxs !mb-xs" label="Parrainage" />
      )}
      {lead.isDemandeDeRappel && (
        <Tag size="md" variant="info" className="!mx-xxs !mb-xs" label="Demande de rappel" />
      )}
    </div>
    {lead.acquisitionLabel && <div>{lead.acquisitionLabel}</div>}
    {lead.metadata.conversionButton && <div>Besoin client : {lead.metadata.conversionButton}</div>}
    <br />
    <Answers lead={lead} />
  </>
);
