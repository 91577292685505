import React from 'react';

import classNames from 'classnames';

import { Button } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/Button';
import { ProRegistrationAdminView } from '@travauxlib/shared/src/types/api/admin/ProRegistrationAdminView';
import { novareConstructionSiret } from '@travauxlib/shared/src/utils/constants';

import { lostReasonsClubPro } from 'utils/constants';

type Props = {
  proRegistration: ProRegistrationAdminView;
  isUpdating: boolean;
  updateProRegistration: (payload: {
    proRegistration: ProRegistrationAdminView;
    preventMail?: boolean;
    isClubPro?: boolean;
    lostReason?: string;
    status: ProRegistrationAdminView['status'];
  }) => void;
  lostReason: String | undefined;
};

export const LostReasonsSection: React.FC<Props> = ({
  proRegistration,
  updateProRegistration,
  isUpdating,
}) => {
  const { companyType, siret, legalState, phoneNumber } = proRegistration;
  const shouldDisplayArchitecte = companyType === 'architecte';
  const shouldDisplayPhoneNumber = phoneNumber && !phoneNumber.match(/^0(6|7)/);
  const shouldDisplaySocieteNotCreated = siret === novareConstructionSiret;
  const shouldDisplaySiretDead = legalState === 'Dead';

  const lostReasonsClubProButton: { label: string; preventMail?: boolean }[] = [
    { label: 'Ouverture compte logiciel de devis', preventMail: true },
    ...(shouldDisplayArchitecte ? [{ label: "Architecte d'intérieur" }] : []),
    ...(shouldDisplayPhoneNumber ? [{ label: 'Numéro de téléphone fixe' }] : []),
    ...(shouldDisplaySocieteNotCreated ? [{ label: 'Société en cours de création' }] : []),
    ...(shouldDisplaySiretDead ? [{ label: 'Siret radié' }] : []),
    ...[{ label: 'Doublon' }],
  ];

  return (
    <div>
      <div className="!flex !flex-wrap !mb-sm">
        {lostReasonsClubPro.map(({ value, label }) => (
          <Button
            variant={label === "C'était un test" ? 'tertiary' : 'secondary'}
            disabled={isUpdating}
            loading={isUpdating}
            data-testid={value}
            key={value}
            type="button"
            onClick={() =>
              updateProRegistration({
                proRegistration,
                status: 'Lost',
                lostReason: value,
              })
            }
            className={classNames(`!mr-xs !mb-xs`)}
          >
            {label}
          </Button>
        ))}
      </div>
      <Button
        variant="secondary"
        disabled={isUpdating}
        loading={isUpdating}
        data-testid="poubelle"
        type="button"
        onClick={() =>
          updateProRegistration({
            proRegistration,
            status: 'Lost',
            lostReason: 'Poubelle',
          })
        }
        className={classNames('!mr-xs !mb-xs')}
      >
        Poubelle
      </Button>
      <div>
        <hr />
        <div className="!font-bold !mb-sm">Club Pro</div>
        <hr />
        <div className="!flex !flex-wrap !mb-sm">
          {lostReasonsClubProButton.map(item => (
            <Button
              variant="secondary"
              key={item.label}
              type="button"
              disabled={isUpdating}
              loading={isUpdating}
              onClick={() =>
                updateProRegistration({
                  proRegistration,
                  status: 'Lost',
                  lostReason: item.label,
                  preventMail: item.preventMail,
                })
              }
              className={classNames('!mr-xs !mb-xs')}
            >
              {item.label}
            </Button>
          ))}
        </div>
      </div>
    </div>
  );
};
