import { useMutation, useQueryClient } from '@tanstack/react-query';

import { ProRegistrationAdminView } from '@travauxlib/shared/src/types/api/admin/ProRegistrationAdminView';
import { request } from '@travauxlib/shared/src/utils/request';

import { PRO_REGISTRATION_KEY } from './useProRegistrations';

export type UpdateProRegistrationPayload = {
  proRegistration: ProRegistrationAdminView;
  status: ProRegistrationAdminView['status'];
  lostReason?: string;
  isClubPro?: boolean;
  preventMail?: boolean;
};

const handleUpdateProRegistration = async (
  payload: UpdateProRegistrationPayload,
): Promise<void> => {
  const { uuid } = payload.proRegistration;
  const requestURL = `${APP_CONFIG.apiURL}/admin/pro-registrations/${uuid}/status`;
  const result = await request(requestURL, {
    method: 'PATCH',
    body: payload,
  });
  if (payload.status === 'Adressable' && payload.isClubPro && result.pipedriveDealUrl) {
    window.open(result.pipedriveDealUrl, '_blank', 'noreferrer');
  }
};

export const useUpdateProRegistration = (): {
  updateProRegistration: typeof handleUpdateProRegistration;
  isUpdating: boolean;
} => {
  const queryClient = useQueryClient();

  const { isPending: isUpdating, mutateAsync: updateProRegistration } = useMutation({
    mutationFn: handleUpdateProRegistration,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [PRO_REGISTRATION_KEY] });
    },
  });
  return { updateProRegistration, isUpdating };
};
